<template>
  <div :id="modalId" class="modalPopup">
    <div class="modalPopup-content">
      <div class="modalPopup-header">
        <span class="close" @click="closeEvent">&times;</span>
        <h2>{{ headerTitle }}</h2>
      </div>
      <div class="modalPopup-body">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modalId: {
      type: String,
      default: "modal",
    },
    headerTitle: {
      type: String,
      default: "Modal",
    },
  },
  components: {},
  data: () => {
    return {};
  },
  methods: {
    closeEvent() {
        this.$emit('closeEvent', true);
    }
  },
};
</script>
<style>
/* The Modal (background) */
.modalPopup {
  display: block !important ; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modalPopup-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 100%;
  max-width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modalPopup-header {
  padding: 2px 16px;
  background-color: #5ca6b8;
  color: white;
}
.modalPopup-header h2 {
    font-size: 18px;
}
.modalPopup-body {
  padding: 10px;
}

</style>
