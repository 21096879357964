<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <!-- Page Header -->
        <div class="page-header">
          <div class="row">
            <div class="col">
              <h3 class="page-title">Documents</h3>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/profile/edit">Profile</router-link>
                </li>
                <li class="breadcrumb-item active">Documents</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
        <div class="tab-content profile-tab-cont">

          <!-- Document Tab -->
          <div class="tab-pane fade show active" id="all_documents">
            <div class="row">
              <div class="col-lg-5">
                <div class="card">
                  <div class="card-body">
                    <h4 class="user-name mb-0">Add New Document</h4>
                    <Form
                      ref="documentForm"
                      id="documentForm"
                      @submit="onDocumentSubmit"
                    >
                      <div class="form-group">
                        <p class="settings-label">
                          Title <span class="star-red">*</span>
                        </p>
                        <Field name="title" type="text" class="form-control" />
                      </div>

                      <div class="form-group">
                        <p class="settings-label">
                          Description <span class="star-red">*</span>
                        </p>
                        <Field
                          name="description"
                          type="textarea"
                          class="form-control"
                        />
                      </div>

                      <div class="form-group">
                        <p class="settings-label">
                          File <span class="star-red">*</span>
                        </p>
                        <Field
                          name="file"
                          type="file"
                          id="document_file"
                          @change="upload($event)"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group text-center">
                        <button
                          class="btn btn-primary btn-block"
                          type="submit"
                          value="Login"
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>

              <div class="col-lg-7">
                <div class="card">
                  <div class="card-body">
                    <h5 class="user-name mb-0">All Documents</h5>
                    <div
                      class="card"
                      v-if="profile.mediaFiles && profile.mediaFiles.length > 0"
                    >
                      <div
                        class="card-body"
                        v-for="(mediaFile, index) in profile.mediaFiles"
                        :key="index"
                      >
                        <h6 class="user-name mb-0">
                          <a
                            style="
                              color: orange;
                              text-decoration-line: underline;
                            "
                            :href="siteURL + '' + mediaFile.files[0]?.url"
                            download=""
                            target="_blank"
                            >{{ mediaFile.files[0]?.name }}</a
                          >
                        </h6>
                        <p>
                          {{ mediaFile.fileName }}<br />{{
                            mediaFile.fileDescription
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { notify } from "@kyvg/vue3-notification";
import Request, { API, siteURL } from "../../request";
import { router } from "../../router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { ref } from "vue";

export default {
  components: {
    Form,
    Field,
  },
  data: () => {
    return {      
      profile: {},
      title: "",
      description: "",
      files: "",
      siteURL: siteURL,
    };
  },
  async beforeMount() {
    await this.getProfile();
  },
  methods: {
    async onDocumentSubmit(e) {
      var formData = {
        fileName: e.title,
        fileDescription: e.description,
        files: this.files
          ? [
              {
                status: "done",
                type: this.files.mimetype,
                uid: "rc-upload-" + this.profile._id + "-2",
                name: this.files.originalname,
                size: this.files.size,
                response: this.files,
                originFileObj: {
                  uid: "rc-upload-" + this.profile._id + "-2",
                  type: this.files.mimetype,
                  name: this.files.originalname,
                  size: this.files.size,
                },
              },
            ]
          : null,
        _id: this.profile._id,
      };
      const response = await Request.postRequest(
        "studentPanel/addDocument",
        formData
      );
      if (response.error === true) {
        notify({
          type: "warn",
          title: response.msg,
        });
      } else {
        document.getElementById("documentForm").reset();
        document.getElementById("document_file").value = "";
        notify({
          type: "success",
          title: response.msg,
        });
        await this.getProfile();
      }
    },
    async upload(event) {
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file);

      const response = await Request.postRequest("filesUploader", formData);
      this.files = response;
    },
    async getProfile() {
      const response = await Request.getRequest("studentPanel/getProfile");
      if (response.error === false) {
        this.profile = response.doc;
      }
    },
  },
};
</script>
<style>
.antd-pro-containers-student-inquiry-add-style-infoBox1 {
  margin-top: 1px;
  justify-self: center;
  align-self: center;
  background-color: #ffad49;
  padding: 20px;
  color: white;
  font-size: 12px;
  font-size: 13px;
}
.tab-header {
  background-color: #ffad49;
  padding: 10px;
  color: #fff;
  margin-top: -1px;
  margin-left: 0px;
  border-radius: 3px 3px 0px 0px;
  width: 100.1%;
  cursor: pointer;
}
#updateProfileForm .card-body {
  padding: 5px 15px;
}
#updateProfileForm .card-body .card {
  margin-bottom: 5px;
}
.tags-input-badge {
  position: relative;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tags-input-badge-pill {
  padding-right: 1.25em;
  padding-left: 0.6em;
  border-radius: 10em;
}
.tags-input-badge-selected-default {
  color: #212529;
  background-color: #f0f1f2;
}
.tags-input span {
  margin-right: 0.3em;
}
.tags-input span {
  margin-right: 0.3em;
}
.tags-input-remove {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  right: 0.3em;
  top: 0.3em;
  padding: 0.5em;
  overflow: hidden;
}
.tags-input-remove:before,
.tags-input-remove:after {
  content: "";
  position: absolute;
  width: 75%;
  left: 0.15em;
  background: #5dc282;
  height: 2px;
  margin-top: -1px;
}
.tags-input-remove:before {
  transform: rotate(45deg);
}
.tags-input-remove:after {
  transform: rotate(-45deg);
}
.tags-input input {
  flex: 1;
  background: transparent;
  border: none;
}
.tags-input input[type="text"] {
  color: #495057;
}
.select2-container {
	display: inline !important;
}
</style>
