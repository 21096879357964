<template>
    <!-- Sidebar -->
    <div class="sidebar" id="sidebar">
        <div class="sidebar-inner">
            <perfect-scrollbar class="scroll-area"  :settings="settings" @ps-scroll-y="scrollHanle">
                <div id="sidebar-menu" class="sidebar-menu">
                    <ul>
                        <li><router-link to="/dashboard"><i class="feather-grid"></i><span>Dashboard</span></router-link></li>
                        <li><router-link to="/profile/edit"><i class="feather-user"></i><span>Profile</span></router-link></li>
                        <li><router-link to="/profile/documents"><i class="feather-upload"></i><span>Upload Documents</span></router-link></li>
                        <li v-if="profile.atLevel != 'Claim Desk'"><router-link to="/my/applications"><i class="feather-list"></i><span>My Applications</span></router-link></li>
                        <li v-if="profile.atLevel == 'Front Desk' || profile.atLevel == 'Registration Desk' || profile.atLevel == 'Operation Manager Desk'"><router-link to="/apply-now"><i class="feather-send"></i><span>Apply Now</span></router-link></li>
                    </ul>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <!-- /Sidebar -->
</template>

<script>
import {PerfectScrollbar}  from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
export default {
   components: {
        PerfectScrollbar ,
    },
    mounted() {
        var Sidemenu = function() {
		    this.$menuItem = $('#sidebar-menu a');
	    };
        var $this = Sidemenu;
		$('#sidebar-menu a').on('click', function(e) {
			if($(this).parent().hasClass('submenu')) {
				e.preventDefault();
			}
			if(!$(this).hasClass('subdrop')) {
				$('ul', $(this).parents('ul:first')).slideUp(350);
				$('a', $(this).parents('ul:first')).removeClass('subdrop');
				$(this).next('ul').slideDown(350);
				$(this).addClass('subdrop');
			} else if($(this).hasClass('subdrop')) {
				$(this).removeClass('subdrop');
				$(this).next('ul').slideUp(350);
			}
		});
		$('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
    },
    computed: {
        currentPath() {
            return this.$route.name;
        }
    },

    data() {
        return {
            settings: {
                suppressScrollX: true,
            },
            activeClass: 'active',
            profile: {}
        };
        //  isactive : true
    },
    methods: {
        scrollHanle(evt) {
            console.log(evt)
        }
    },
    beforeMount() {
        this.profile = JSON.parse(localStorage.getItem("user"));
    }
}
</script>
    <style>
    .scroll-area {
        position: relative;
        margin: auto;
        height: calc(100vh - 60px);
        background-color: transparent !important;
    }
</style>
