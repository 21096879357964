<template>
    <!-- User Menu -->
    <li class="nav-item dropdown has-arrow" v-if="currentUser">
        <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
            <span class="user-img">
                <img v-if="currentUser.userImage && currentUser.userImage.url" :src="siteURL+currentUser.userImage.url" :alt="currentUser.name" class="rounded-circle" width="31" />
                <img v-else src="../assets/img/profiles/avatar-01.jpg" :alt="currentUser.name" class="rounded-circle" width="31" />
            </span>
        </a>
        <div class="dropdown-menu">
            <div class="user-header">
                <div class="avatar avatar-sm">
                    <img v-if="currentUser.userImage && currentUser.userImage.url" :src="siteURL+currentUser.userImage.url" :alt="currentUser.name" class="avatar-img rounded-circle" />
                    <img v-else src="../assets/img/profiles/avatar-01.jpg" :alt="currentUser.name" class="avatar-img rounded-circle" />
                </div>
                <div class="user-text">
                    <h6>{{currentUser.name}}</h6>
                    <p class="text-muted mb-0">{{currentUser.email}}</p>
                </div>
            </div>
            <router-link class="dropdown-item" to="/profile/edit">Profile</router-link>
            <a class="dropdown-item" href="#" @click="logoutUser()">Logout</a>
        </div>
    </li>
    <!-- /User Menu -->
</template>
<script>
	import {siteURL} from './../request'
    import { router } from './../router';
    import { notify } from "@kyvg/vue3-notification"
	export default {
	  	components: {
		},
		data: () => {
			return {
				currentUser:{},
                siteURL:siteURL
			}
		},
        methods: {
            logoutUser() {
                notify({
                    type: 'success',
                    title: "Logout successfully"
                });
                localStorage.setItem('token', '');
                localStorage.setItem('user', '');
                router.push('/')
            }
        },
		beforeMount() {
            this.currentUser = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):null;
            if(localStorage.getItem('token') === null || localStorage.getItem('token') === undefined || this.currentUser === null) {
                notify({
                    type: 'warn',
                    title: "Your session is expired, login again"
                });
                localStorage.setItem('token', '');
                localStorage.setItem('user', '');
                router.push('/')
            }
		},	
	}
</script>