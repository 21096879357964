<template>
    <!-- Main Wrapper -->
	<div class="main-wrapper">
		<layout-header></layout-header>
		<layout-sidebar></layout-sidebar>
		
		<!-- Page Wrapper -->
		<div class="page-wrapper">
			<div class="content container-fluid">
				<!-- Page Header -->
				<div class="page-header">
					<div class="row">
						<div class="col">
							<h3 class="page-title">Profile</h3>
							<ul class="breadcrumb">
								<li class="breadcrumb-item"><router-link to="/dashboard">Dashboard</router-link></li>
								<li class="breadcrumb-item active">Profile</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- /Page Header -->
				
				<div class="row">
					<div class="col-md-12">
						<div class="profile-header">
							<div class="row align-items-center">
								<div class="col-auto profile-image">
									<label for="profile_photo">
										<img v-if="profile.profilePic && profile.profilePic.length > 0" class="rounded-circle" :alt="profile.studentName" :src="siteURL+''+profile.profilePic[0].url" />
										<img v-else class="rounded-circle" :alt="profile.studentName" src="../../assets/img/profiles/avatar-02.jpg" />										
									</label>
									<Field type="file" name="profile_photo"  @change="uploadProfilePic($event)" id="profile_photo" accept="image/*" style="display: none;"/>
								</div>
								<div class="col ms-md-n2 profile-user-info">
									<h4 class="user-name mb-0">{{profile.studentName}}</h4>
									<h6 class="text-muted" v-if="profile.studentBranchId"><b>Branch</b>:- {{profile.studentBranchId.branchAddress}}</h6>
									<h6 class="text-muted"><b>Desk</b>:- {{profile.atLevel}}</h6>
								</div>
								<div class="col-auto profile-btn">
									<router-link to="/profile/edit" class="btn btn-primary">
										Edit
									</router-link>
								</div>
							</div>
						</div>
						<div class="profile-menu">
							<ul class="nav nav-tabs nav-tabs-solid">
								<li class="nav-item">
									<a class="nav-link active" data-bs-toggle="tab" href="#inquiry">Profile</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" data-bs-toggle="tab" href="#all_documents">Documents</a>
								</li>
							</ul>
						</div>	
						<div class="tab-content profile-tab-cont">
							
							<!-- Personal Details Tab -->
							<div class="tab-pane fade show active" id="inquiry">
							
								<!-- Personal Details -->
								<div class="row">
									<div class="col-lg-12">
										<div class="card-body">
											<div class="card">
												<h5 class="card-title d-flex justify-content-between tab-header" @click="openNextTab('basicInfo')">
													<span>Basic Information As Per Passport</span> 
												</h5>
												<div class="col-md-12" style="padding:15px;" v-if="activeTab == 'basicInfo'">
													<div class="row">
														<div class="col-md-6" v-if="profile.profile">

															<div class="form-group">
																<p class="settings-label">Enquiry Type <span class="star-red">*</span></p>
																<span class="form-control">{{profile.selectInquiryType == 'STUDENT'?'Study Abroad':profile.selectInquiryType}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Name <span class="star-red">*</span></p>
																<span class="form-control">{{profile.studentName}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Date of Birth <span class="star-red">*</span></p>
																<span class="form-control">{{dateOfBirth}}</span>
															</div>	

															<div class="form-group">
																<p class="settings-label">Address <span class="star-red">*</span></p>
																<span class="form-control">{{profile.profile.address}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Gender <span class="star-red">*</span></p>
																<span class="form-control">{{profile.gender}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Mode </p>
																<span class="form-control">{{profile.mode}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Phone <span class="star-red">*</span></p>
																<span class="form-control">{{profile.mobile}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Parent's Mobile No</p>
																<span class="form-control">{{profile.landline}}</span>
															</div>

														</div>
														<div class="col-md-6">

															<div class="form-group">
																<p class="settings-label">Whatsapp</p>
																<span class="form-control">{{profile.watsupp}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Email Id</p>
																<span class="form-control">{{profile.emailId}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Facebook Id</p>
																<span class="form-control">{{profile.facebookEmail}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Passport</p>
																<span class="form-control">{{profile.passport}}</span>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="card-body">
											<div class="card">
												<h5 class="card-title d-flex justify-content-between tab-header" @click="openNextTab('studyAbroad')">
													<span>Study Abroad Information</span> 
												</h5>
												<div class="col-md-12" style="padding:15px;" v-if="activeTab == 'studyAbroad'">
													<div class="row">
														<div class="col-md-6">

															<div class="form-group">
																<p class="settings-label">Year Planning To Go <span class="star-red">*</span></p>
																<span class="form-control">{{profile.whichYearPlanningToGo}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Select Test </p>
																<span class="form-control">{{profile.testType}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Overall Score </p>
																<span class="form-control">{{profile.ieltsOverallScore}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Reading Score </p>
																<span class="form-control">{{profile.profile.reading}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Writing Score </p>
																<span class="form-control">{{profile.profile.writing}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Listening Score </p>
																<span class="form-control">{{profile.profile.listening}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Speaking Score </p>
																<span class="form-control">{{profile.profile.speaking}}</span>
															</div>

															<div class="form-group">
																<p class="settings-label">Study Level <span class="star-red">*</span></p>
																<span class="form-control">{{profile.enqType}}</span>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="card-body">
											<div class="card">
												<h5 class="card-title d-flex justify-content-between tab-header" @click="openNextTab('preference')">
													<span>Preference</span> 
												</h5>
												<div class="col-md-12" style="padding:15px;" v-if="activeTab == 'preference'">
													<div class="row">

														<div class="col-md-12 form-group">
															<div class="table-responsive">
																<table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
																	<thead class="student-thread">
																		<th>Country</th>
																		<th>City</th>
																	</thead>
																	<tbody>
																		<tr
																			v-if="profile.totalPreference && profile.totalPreference.length"
																			v-for="(preference, index) in profile.totalPreference"
																			:key="index"
																			>
																			<td>
																				<h2>
																					{{ preference.country }}
																				</h2>
																			</td>
																			<td>{{ preference.city }}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="card-body">
											<div class="card">
												<h5 class="card-title d-flex justify-content-between tab-header" @click="openNextTab('qualification')">
													<span>Qualification</span> 
												</h5>
												<div class="col-md-12" style="padding:15px;" v-if="activeTab == 'qualification'">
													<div class="row">
														
														<div class="col-md-12 form-group">
															<div class="table-responsive">
																<table class="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
																	<thead class="student-thread">
																		<th>Qualification</th>
																		<th>Passing Year</th>
																		<th>Percentage</th>
																		<th>Maths</th>
																		<th>English</th>
																		<th>Stream</th>
																		<th>Major Subjects</th>
																		<th>Backlogs</th>
																		<th>Degree Type</th>
																	</thead>
																	<tbody>
																		<tr
																			v-if="profile.profile && profile.profile.newQualification && profile.profile.newQualification.length"
																			v-for="(newQualification, index) in profile.profile.newQualification"
																			:key="index"
																			>
																			<td>
																				<h2>
																					{{ newQualification.qualification }}
																				</h2>
																			</td>
																			<td>{{ newQualification.passingYear }}</td>
																			<td>{{ newQualification.percentage }}</td>
																			<td>{{ newQualification.maths }}</td>
																			<td>{{ newQualification.english }}</td>
																			<td>{{ newQualification.stream }}</td>
																			<td>{{ newQualification.major }}</td>
																			<td>{{ newQualification.backlogs }}</td>
																			<td>{{ newQualification.degreeType }}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>										
									</div>
								</div>
								<!-- /Personal Details -->

							</div>
							<!-- /Personal Details Tab -->

							<!-- Change all_documents Tab -->
							<div id="all_documents" class="tab-pane fade">							
								<div class="row">

									<div class="col-lg-5">
										<div class="card">
											<div class="card-body">
												<h4 class="user-name mb-0">Add New Document</h4>
												<Form ref="documentForm" id="documentForm" @submit="onSubmit">

													<div class="form-group">
														<p class="settings-label">Title <span class="star-red">*</span></p>
														<Field name="title" type="text" class="form-control" />
													</div>

													<div class="form-group">
														<p class="settings-label">Description <span class="star-red">*</span></p>
														<Field name="description" type="textarea" class="form-control" />
													</div>

													<div class="form-group">
														<p class="settings-label">File <span class="star-red">*</span></p>
														<Field name="file" type="file" id="document_file" @change="upload($event)" class="form-control" />
													</div>
													<div class="form-group text-center">
														<button class="btn btn-primary btn-block" type="submit" value="Login">Save</button>
													</div>
												</Form>
											</div>
										</div>
									</div>

									<div class="col-lg-7">
										<div class="card">
											<div class="card-body">
												<h5 class="user-name mb-0">All Documents</h5>
												<div class="card" v-if="profile.mediaFiles && profile.mediaFiles.length > 0">
													<div class="card-body" v-for="(mediaFile, index) in profile.mediaFiles" :key="index">
														<h6 class="user-name mb-0"><a style="color: orange; text-decoration-line: underline;" :href="siteURL+''+mediaFile.files[0]?.url" download="" target="_blank">{{mediaFile.files[0]?.name}}</a></h6>
														<p>{{mediaFile.fileName}}<br/>{{mediaFile.fileDescription}}</p>
													</div>
												</div>
											</div>
										</div>									
									</div>
								
								</div>

							</div>
							<!-- /Change all_documents Tab -->
							
						</div>
					</div>
				</div>
			
			</div>			
		</div>
		<!-- /Page Wrapper -->
	
	</div>
	<!-- /Main Wrapper -->
</template>
<script>
	import { notify } from "@kyvg/vue3-notification"
	import Request, {API, siteURL} from '../../request'
	import { router } from '../../router';
	import { Form, Field } from 'vee-validate';
	export default {
	  	components: {
			Form,
			Field,
		},
		data: () => {
			return {
				activeTab: 'basicInfo',
				profile:{},
				title:'',
				description: '',
				files:'',
				siteURL:siteURL
			}
		},
		async beforeMount() {
			await this.getProfile();
		},
		methods: {
			async upload(event){
    			let file = event.target.files[0];
				let formData = new FormData();
				formData.append('file', file)
				
				const response = await Request.postRequest('filesUploader', formData);
				this.files = response;
			},
			openNextTab(tab) {
				this.activeTab = tab;
			},
			async uploadProfilePic(event){
    			let file = event.target.files[0];
				let formData = new FormData();
				formData.append('file', file)
				
				const response = await Request.postRequest('filesUploader', formData);
				if(response) {
					const profilePicData = {
						profilePic: [{
							status: "done",
							type: response.mimetype,
							uid: "rc-upload-"+this.profile._id+"-2",
							name: response.originalname,
							size: response.size,
							response: response,
							originFileObj: {
								uid: "rc-upload-"+this.profile._id+"-2",
								type: response.mimetype,
								name: response.originalname,
								size: response.size,
							}
						}],
						_id: this.profile._id
					}
					const result = await Request.postRequest('studentPanel/updateProfilePic', profilePicData);
					if(response.error === true) {
						notify({
							type: 'warn',
							title: result.message
						});
					} else {
						notify({
							type: 'success',
							title: result.message
						});
						await this.getProfile();
					}
				}				
			},			
			async onSubmit(e) {
				var formData = {
					fileName: e.title,
					fileDescription: e.description,
					files: this.files?[{
						status: "done",
						type: this.files.mimetype,
						uid: "rc-upload-"+this.profile._id+"-2",
						name: this.files.originalname,
						size: this.files.size,
						response: this.files,
						originFileObj: {
							uid: "rc-upload-"+this.profile._id+"-2",
							type: this.files.mimetype,
							name: this.files.originalname,
							size: this.files.size,
						}
					}]:null,
					_id: this.profile._id
				};				
				const response = await Request.postRequest('studentPanel/addDocument', formData);
				if(response.error === true) {
					notify({
						type: 'warn',
						title: response.msg
					});
				} else {
					document.getElementById("documentForm").reset();
					document.getElementById("document_file").value=""
					notify({
						type: 'success',
						title: response.msg
					});
					await this.getProfile();
				}
			},
			async getProfile() {
				const response = await Request.getRequest('studentPanel/getProfile');
				if(response.error === false) {
					this.profile = response.doc;
				}
			},
		}
	}
</script>