<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <!-- Page Header -->
        <div class="page-header">
          <div class="row">
            <div class="col">
              <h3 class="page-title">Apply Now</h3>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/profile/edit">Profile</router-link>
                </li>
                <li class="breadcrumb-item active">Apply Now</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
        <div class="tab-content profile-tab-cont" style="padding-bottom: 50px">
          <div class="tab-pane fade show active" id="all_course">
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-4" v-if="choosedCourses && choosedCourses.length > 0">
                <button
                  type="button"
                  class="btn btn-success text-white"
                  style="float: right; margin-bottom: 10px;"
                  @click="saveSelectedCourses()"
                >
                  Save Selected Courses
                </button>
              </div>
            </div>
            <div class="row">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Course</th>
                      <th>University</th>
                      <th>Application Fee</th>
                      <th>Intakes</th>
                      <th>Annual Fees/Duration</th>
                      <th>City/State/Country</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(course, index) in courses" :key="index">
                      <td>
                        <button
                          type="button"
                          @click="
                            showModal = true;
                            selectedCourse = course;
                          "
                          class="btn btn-info text-white"
                        >
                          <i class="feather-eye"></i></button
                        >&nbsp;
                        <button
                          type="button"
                          class="btn btn-primary text-white"
                          @click="applyNowForCourse(course)"
                          v-if="!choosedCourses.includes(course._id)"
                        >
                          <i class="feather-check"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger text-white"
                          @click="removeNowFromCourse(course)"
                          v-if="choosedCourses.includes(course._id)"
                        >
                          <i class="feather-trash"></i>
                        </button>
                      </td>
                      <td>
                        {{ course.courseName }} <br /><small
                          v-if="checkIeltsRequirement(course)"
                          style="color: red"
                          >{{ checkIeltsRequirement(course) }} Required for this
                          course.</small
                        >
                      </td>
                      <td>{{ course.universityInstitutionName }}</td>
                      <td>
                        {{
                          course.contactApplicationFee !== "0"
                            ? course.currencyName +
                              " " +
                              course.contactApplicationFee
                            : "NIL"
                        }}
                      </td>
                      <td>{{ getIntakes(course) }}</td>
                      <td>
                        <div>
                          <div>
                            {{
                              course.tutionFee !== "0"
                                ? course.currencyName + " " + course.tutionFee
                                : "NIL"
                            }}/{{ course.courseDuration }}
                          </div>
                        </div>
                      </td>
                      <td>
                        {{ course.universityCityId }}/{{
                          course.universityStateId
                        }}/{{ course.universityCountryId }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="showModal"
      @closeEvent="showModal = false"
      modalId="view_course_details"
      headerTitle="Course Details"
    >
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <h4>Entry Requirements</h4>
          <span
            v-if="
              selectedCourse.ieltsRequirements &&
              selectedCourse.ieltsRequirements.length > 0
            "
          >
            <div
              v-for="(
                ieltsRequirement, index
              ) in selectedCourse.ieltsRequirements"
              :key="index"
            >
              <div>
                <small>ExamType: {{ ieltsRequirement.ExamType }}</small
                ><br />
                <small>Overall: {{ ieltsRequirement.Overall }}</small
                ><br />
                <small>Reading: {{ ieltsRequirement.Reading }}</small
                ><br />
                <small>Writing: {{ ieltsRequirement.Writing }}</small
                ><br />
                <small>Listening: {{ ieltsRequirement.Listening }}</small
                ><br />
                <small>Speaking: {{ ieltsRequirement.Speaking }}</small
                ><br /><br />
              </div>
            </div>
          </span>
        </div>
        <div class="col-md-6 col-sm-12">
          <h4>Qualification</h4>
          <span
            v-if="
              selectedCourse.qualification &&
              selectedCourse.qualification.length > 0
            "
          >
            <div
              v-for="(qualification, index) in selectedCourse.qualification"
              :key="index"
            >
              <div>
                <small v-if="qualification.qualification"
                  >Qualification: {{ qualification.qualification }}<br
                /></small>
                <small v-if="qualification.percentage"
                  >Percentage: {{ qualification.percentage }}<br
                /></small>
                <small v-if="qualification.maths"
                  >Maths: {{ qualification.maths }}<br
                /></small>
                <small v-if="qualification.english"
                  >English: {{ qualification.english }}<br
                /></small>
                <small v-if="qualification.backlogs"
                  >Backlogs: {{ qualification.backlogs }}<br
                /></small>
                <small v-if="qualification.degreeType"
                  >Degree Type: {{ qualification.degreeType }}<br
                /></small>
                <small v-if="qualification.passingYear"
                  >Passing Year: {{ qualification.passingYear }}<br
                /></small>
                <small v-if="qualification.stream"
                  >Stream: {{ qualification.stream.join() }}<br /></small
                ><br />
              </div>
            </div>
          </span>
        </div>
      </div>
    </modal>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { notify } from "@kyvg/vue3-notification";
import { router } from "../../router";
import Request from "../../request";
import moment from "moment";
export default {
  data: () => {
    return {
      courses: [],
      selectedCourse: {},
      profile: {},
      counsellingDesk: {},
      page: 1,
      results: 20,
      count: 20,
      showModal: false,
      choosedCourses: []
    };
  },
  async beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    await this.getProfile();
    await this.getEligibleCourse();
  },
  methods: {
    applyNowForCourse(course){
      this.counsellingDesk.course.push(course);
      this.choosedCourses.push(course._id);
    },
    removeNowFromCourse(course) {
      let lastChoosedIndex = null;
      this.choosedCourses.map((choosedCourse, index) => {
          if(choosedCourse == course._id) {
            lastChoosedIndex = index;
          }
      })
      if(lastChoosedIndex != null) {
        this.choosedCourses.splice(lastChoosedIndex, 1);
      }

      let lastCourseIndex = null;
      this.counsellingDesk.course.map((course, index) => {
          if(course.courseId == course._id) {
            lastCourseIndex = index;
          }
      })
      if(lastCourseIndex != null) {
        this.counsellingDesk.course.splice(lastCourseIndex, 1);
      }
    },
    async saveSelectedCourses() {
      let dataObject = {
        counsellingDesk: this.counsellingDesk,
        _id: this.profile._id
      };
      let response = await Request.postRequest('student/add/counselingDetails', dataObject);
      if (response.error === true) {
        notify({
          type: "warn",
          title: response.msg,
        });
      } else {
        notify({
          type: "success",
          title: response.msg,
        });
        router.push("/my/applications");
      }
    },
    checkIeltsRequirement(record) {
      let { inquiryProfile } = this.profile;
      let ifBandExists = [];
      if (
        record &&
        record.ieltsRequirements &&
        record.ieltsRequirements.length > 1
      ) {
        record.ieltsRequirements &&
          record.ieltsRequirements.map(function (item, index) {
            if (
              (item.Overall > 0 ||
                item.Reading > 0 ||
                item.Writing > 0 ||
                item.Listening > 0 ||
                item.Speaking > 0) &&
              (!inquiryProfile ||
                inquiryProfile.listening === 0 ||
                inquiryProfile.reading === 0 ||
                inquiryProfile.speaking === 0 ||
                inquiryProfile.writing === 0)
            ) {
              ifBandExists.push(item.ExamType);
            }
          });
      }
      return ifBandExists.join("/");
    },
    getIntakes(record) {
      let intakes = [];
      if (record && record.intakes && record.intakes.length > 0) {
        record.intakes.map((val, inde) => {
          intakes.push(moment(val).format("MMM"));
        });
      }
      return intakes.join(", ");
    },
    async getEligibleCourse() {
      this.courses = [];
      const response = await Request.getRequest(
        `/suggested/course/list?businessId=${this.user.businessId}&inquiryId=${this.user._id}&results=${this.results}&count=${this.count}&page=${this.page}`
      );
      if (response.data && response.data.length > 0) {
        this.courses = response.data;
      }
    },
    async getProfile() {
      const response = await Request.getRequest("studentPanel/getProfile");
      let $this = this;
      if (response.error === false) {
        this.profile = response.doc;
        this.counsellingDesk = this.profile.counsellingDesk;
        if(this.counsellingDesk && this.counsellingDesk.course && this.counsellingDesk.course.length > 0) {
          this.counsellingDesk.course.map(function(course, index) {
            $this.choosedCourses.push(course.courseId);
          })
        }
      }
    },
  },
};
</script>
<style>
#all_course th,
#all_course td {
  font-size: 12px;
}
#all_course th:first-child,
#all_course td:first-child {
  white-space: nowrap;
}
</style>
