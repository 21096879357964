import Vue, { createApp } from 'vue';
import { router } from './router';
import App from "./App.vue";

/***********Layout **********/
import LayoutHeader from './views/layouts/Header'
import Sidebar from './views/layouts/Sidebar'
import Footer from './views/layouts/Footer'

/***********Layout Components **********/
import Usermenu from './components/usermenu.vue'
import Notification from './components/notification.vue'
import Mobilemenu from './components/mobilemenu.vue'
import Search from './components/search.vue'
import Toggle from './components/toggle.vue'
import Logo from './components/logo.vue'
import Modal from './components/modal.vue'
/***********Page header components ***************/

import Notifications from '@kyvg/vue3-notification'

/**********Importing plugins ***********/
import './assets/plugins/bootstrap/css/bootstrap.min.css'
import './assets/plugins/bootstrap/js/bootstrap.bundle.min.js'
import './assets/plugins/fontawesome/css/fontawesome.min.css'
import './assets/plugins/fontawesome/css/all.min.css'
import './assets/css/bootstrap-datetimepicker.min.css'
import './assets/css/bootstrap-datetimepicker.min.js'
import './assets/css/style.css'
import './assets/css/bootstrap4.min.css'
import './assets/css/dataTables.bootstrap4.min.css'
import './assets/css/jquery.dataTables.min.js'
import './assets/css/dataTables.bootstrap4.min.js'
import './assets/plugins/simple-calendar/simple-calendar.css'
import './assets/css/feather.css'

import jquery from 'jquery'; 
window.$ = jquery
const app = createApp(App)

app.config.globalProperties.$filters = {
    YYYYMMDDSTRING(value) {
        console.log(value)
        const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        var currentdate = new Date(value);
        var date = (months[currentdate.getMonth()])+' '+((currentdate.getDate() < 10)?"0":"") + currentdate.getDate() +" "+ currentdate.getFullYear();
        return date;
    },
    YYYYMMDD(value) {
        var currentdate = new Date(value);
        var date = ((currentdate.getDate() < 10)?"0":"") + currentdate.getDate() +"-"+((currentdate.getMonth() < 10)?"0":"")+(currentdate.getMonth())+'-'+ currentdate.getFullYear();
        return date;
    }
}

/*Global Components */
app.component('layout-header', LayoutHeader)
app.component('layout-sidebar', Sidebar)
app.component('layout-footer', Footer)
app.component('usermenu', Usermenu)
app.component('notification', Notification)
app.component('mobilemenu', Mobilemenu)
app.component('search', Search)
app.component('toggle', Toggle)
app.component('logo', Logo)
app.component('modal', Modal)

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
app.component('Datepicker', Datepicker);

import Select2 from 'vue3-select2-component'
app.component('Select2', Select2);

import VoerroTagsInput from '@voerro/vue-tagsinput';
app.component('tags-input', VoerroTagsInput);

import ToggleButton from 'vue-js-toggle-button';
app.component('toggle-button', ToggleButton);

app.use(router).use(Notifications)

.mount('#app');