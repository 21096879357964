import {createRouter, createWebHistory} from 'vue-router';

import Login from '../views/auth/Login'
import Dashboard from '../views/dashboard/Index'
import Profile from '../views/profile/Profile'
import EditProfile from '../views/profile/Edit'
import Documents from '../views/profile/Documents'
import MyApplications from '../views/profile/MyApplications'
import ApplyNow from '../views/profile/ApplyNow'
import Register from '../views/auth/Register'

const routes = [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile
    },
    {
      path: '/profile/edit',
      name: 'EditProfile',
      component: EditProfile
    },
    {
      path: '/profile/documents',
      name: 'Documents',
      component: Documents
    },
    {
      path: '/my/applications',
      name: 'MyApplications',
      component: MyApplications
    },
    {
      path: '/apply-now',
      name: 'ApplyNow',
      component: ApplyNow
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    }
  ];
  export const router = createRouter({
    history: createWebHistory(''),
    linkActiveClass: 'active',
    routes
});