import Vue from 'vue';
import axios from 'axios';

// const baseUrl = 'http://localhost:7376/';

// const baseUrl = 'https://api.turningpointonline.in/';

const baseUrl = 'https://cmstvision.tenagents.com/'

export const API = axios.create({
    baseURL: baseUrl
})

export const siteURL = baseUrl;
class Request {
    getHeader() {
        const token = localStorage.getItem('token');
        if(token != undefined && token && token != null) {
            return {
                headers: {
                    'Authorization': 'Bearer '+ token
                }
            }
        }
        return {
            headers: {
                
            }
        }
    }
    error = err => {
        try {
          if (err.response.status === 401) {
            alert('Sorry You Cannot Access Software Here')
          }
        } catch (e) {
        }
    }
    getRequest(url) {
        return new Promise((next, error) => {
            API.get(url, this.getHeader())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {      
                    this.error(err)
                })
        })
    }
    postRequest(url, data) {
        return new Promise((next, error) => {
            API.post(url, data, this.getHeader())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {      
                    this.error(err)
                })
        })
    }
    putRequest(url, data) {
        return new Promise((next, error) => {
            API.patch(url, data, this.getHeader())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {      
                    this.error(err)
                })
        })
    }
    deleteRequest(url, data) {
        return new Promise((next, error) => {
            API.delete(url, data, this.getHeader())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {      
                    this.error(err)
                })
        })
    }
}
export default new Request();