<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <!-- Page Header -->
        <div class="page-header">
          <div class="row">
            <div class="col">
              <h3 class="page-title">Edit Profile</h3>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/profile/edit">Profile</router-link>
                </li>
                <li class="breadcrumb-item active">Edit</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
        <div class="profile-menu">
          <ul class="nav nav-tabs nav-tabs-solid">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#inquiry"
                >Basic Profile</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#passport"
                >Passport</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="tab"
                href="#english_proficiency"
                >English Proficiency</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="tab"
                href="#country_preference"
                >Country Preference</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#academic_details"
                >Academic Details</a
              >
            </li>
          </ul>
        </div>
        <div class="tab-content profile-tab-cont">
          <!-- Personal Details Tab -->

          <div class="tab-pane fade show active" id="inquiry">
            <Form @submit="onSubmit('inquiry')">
              <div class="row">
                <div class="profile-header">
                  <div class="row align-items-center">
                    <div class="profile-image">
                      <label for="profile_photo" style="width: 100%">
                        <img
                          v-if="
                            profile.profilePic && profile.profilePic.length > 0
                          "
                          class="rounded-circle"
                          :alt="profile.studentName"
                          :src="siteURL + '' + profile.profilePic[0].url"
                        />
                        <img
                          v-else
                          class="rounded-circle"
                          :alt="profile.studentName"
                          src="../../assets/img/profiles/avatar-02.jpg"
                        />
                        <span
                          class="btn btn-info pull-right"
                          style="float: right"
                          >Update Photo</span
                        >
                      </label>
                      <Field
                        type="file"
                        name="profile_photo"
                        @change="uploadProfilePic($event)"
                        id="profile_photo"
                        accept="image/*"
                        style="display: none"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <p class="settings-label">
                      Full Name as per passport <span class="star-red">*</span>
                    </p>
                    <Field
                      name="studentName"
                      type="text"
                      v-model="profile.studentName"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">Date of Birth</p>
                    <Datepicker
                      name="dateOfBirth"
                      format="yyyy-MM-dd"
                      v-model="profile.dateOfBirth"
                    ></Datepicker>
                  </div>

                  <div class="form-group">
                    <p class="settings-label">
                      Gender <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="gender"
                      v-model="profile.gender"
                      :options="genderOptions"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">
                      Marital Status <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="maritalStatus"
                      v-model="profile.maritalStatus"
                      :options="maritalStatusOptions"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">How to come you know about us</p>
                    <Select2
                      name="mode"
                      v-model="profile.mode"
                      :options="modeOptions"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <p class="settings-label">
                      Country Code <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="countryCode"
                      readonly
                      disabled
                      v-model="profile.countryCode"
                      :options="phoneCodes"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">
                      Mobile Number <span class="star-red">*</span>
                    </p>
                    <Field
                      name="mobile"
                      type="number"
                      v-model="profile.mobile"
                      readonly
                      disabled
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">
                      If whats app number is different from Phone Number?
                    </p>
                    <input
                      name="ifWhatsAppNumberIsDifferentSwitch"
                      type="checkbox"
                      v-model="profile.ifWhatsAppNumberIsDifferentSwitch"
                    />
                  </div>

                  <div
                    class="form-group"
                    v-if="profile.ifWhatsAppNumberIsDifferentSwitch"
                  >
                    <p class="settings-label">Whatsapp Number</p>
                    <Field
                      name="watsupp"
                      type="number"
                      v-model="profile.watsupp"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">Email Id</p>
                    <Field
                      name="emailId"
                      type="email"
                      v-model="profile.emailId"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <p class="settings-label">
                      Country <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="addressCountryId"
                      v-model="profile.addressCountryId"
                      @select="countryOnChange($event)"
                      :options="countriesArr"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">
                      Address <span class="star-red">*</span>
                    </p>
                    <Field
                      name="address"
                      type="textarea"
                      v-model="profile.profile.address"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">Address 2</p>
                    <Field
                      name="address2"
                      type="textarea"
                      v-model="profile.address2"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">State</p>
                    <Field
                      name="state"
                      type="text"
                      v-model="profile.state"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">City</p>
                    <Field
                      name="city"
                      type="text"
                      v-model="profile.city"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">Pincode</p>
                    <Field
                      name="pincode"
                      type="text"
                      v-model="profile.pincode"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <p class="settings-label">
                      Have you ever applied for any type if immigration/PR?
                    </p>
                    <input
                      name="applicantAppliedAnyTypeImmigrationCountryIdSwitch"
                      type="checkbox"
                      v-model="
                        profile.applicantAppliedAnyTypeImmigrationCountryIdSwitch
                      "
                    />
                  </div>

                  <div
                    class="form-group"
                    v-if="
                      profile.applicantAppliedAnyTypeImmigrationCountryIdSwitch
                    "
                  >
                    <p class="settings-label">Select Country</p>
                    <Select2
                      name="applicantAppliedAnyTypeImmigrationCountryId"
                      v-model="
                        profile.applicantAppliedAnyTypeImmigrationCountryId
                      "
                      :options="countriesArr"
                    />
                  </div>

                  <div
                    class="form-group"
                    v-if="
                      profile.applicantAppliedAnyTypeImmigrationCountryIdSwitch
                    "
                  >
                    <p class="settings-label">
                      Does applicant suffer from a serious medical condition?
                    </p>
                    <Field
                      name="applicantSufferMedicalCondition"
                      type="text"
                      v-model="profile.applicantSufferMedicalCondition"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">
                      Have you been refused visa any country?
                    </p>
                    <input
                      name="applicantVisaRefusalCountryIdSwitch"
                      type="checkbox"
                      v-model="profile.applicantVisaRefusalCountryIdSwitch"
                    />
                  </div>

                  <div
                    class="form-group"
                    v-if="profile.applicantVisaRefusalCountryIdSwitch"
                  >
                    <p class="settings-label">Select Country</p>
                    <Select2
                      name="applicantVisaRefusalCountryId"
                      v-model="profile.applicantVisaRefusalCountryId"
                      :options="countriesArr"
                    />
                  </div>

                  <div
                    class="form-group"
                    v-if="profile.applicantVisaRefusalCountryIdSwitch"
                  >
                    <p class="settings-label">
                      Has applicant ever been convicted of a criminal offence?
                    </p>
                    <Field
                      name="applicantConvictedCriminalOffence"
                      type="text"
                      v-model="profile.applicantConvictedCriminalOffence"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </Form>
          </div>

          <div class="tab-pane fade" id="passport">
            <Form @submit="onSubmit('passport')">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <p class="settings-label">
                      Passport Number <span class="star-red">*</span>
                    </p>
                    <Field
                      name="passportNumber"
                      type="text"
                      v-model="profile.profile.passportNumber"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <p class="settings-label">
                      Expiry Date <span class="star-red">*</span>
                    </p>
                    <Datepicker
                      name="expiryDate"
                      format="yyyy-MM-dd"
                      v-model="profile.expiryDate"
                    ></Datepicker>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <p class="settings-label">
                      Issue Date <span class="star-red">*</span>
                    </p>
                    <Datepicker
                      name="issueDate"
                      format="yyyy-MM-dd"
                      v-model="profile.issueDate"
                    ></Datepicker>
                  </div>
                  <div class="form-group">
                    <p class="settings-label">
                      Issue Country <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="issueCountryId"
                      v-model="profile.issueCountryId"
                      :options="countriesArr"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </Form>
          </div>

          <div class="tab-pane fade" id="english_proficiency">
            <Form @submit="onSubmit('english_proficiency')">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <p class="settings-label">
                      Year Planning To Go <span class="star-red">*</span>
                    </p>
                    <Field
                      name="whichYearPlanningToGo"
                      type="number"
                      v-model="profile.whichYearPlanningToGo"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <p class="settings-label">
                      Do you have an english test score?
                    </p>
                    <input
                      name="haveEnglishTestScore"
                      type="checkbox"
                      v-model="profile.haveEnglishTestScore"
                    />
                  </div>

                  <div class="form-group" v-if="profile.haveEnglishTestScore">
                    <p class="settings-label">Select Test</p>
                    <Select2
                      name="testType"
                      v-model="profile.testType"
                      :options="testTypeOptions"
                      @change="getTestTypeScores($event)"
                      @select="getTestTypeScores($event)"
                    />
                  </div>

                  <div class="form-group" v-if="profile.haveEnglishTestScore">
                    <p class="settings-label">Speaking Score</p>
                    <Select2
                      name="ieltsOverallScore"
                      v-model="profile.profile.speaking"
                      :options="testTypeScores"
                      @change="calculateOverAll($event)"
                      @select="calculateOverAll($event)"
                    />
                  </div>

                  <div class="form-group" v-if="profile.haveEnglishTestScore">
                    <p class="settings-label">Listening Score</p>
                    <Select2
                      name="listening"
                      v-model="profile.profile.listening"
                      :options="testTypeScores"
                      @change="calculateOverAll($event)"
                      @select="calculateOverAll($event)"
                    />
                  </div>

                  <div class="form-group" v-if="profile.haveEnglishTestScore">
                    <p class="settings-label">Writing Score</p>
                    <Select2
                      name="writing"
                      v-model="profile.profile.writing"
                      :options="testTypeScores"
                      @change="calculateOverAll($event)"
                      @select="calculateOverAll($event)"
                    />
                  </div>

                  <div class="form-group" v-if="profile.haveEnglishTestScore">
                    <p class="settings-label">Reading Score</p>
                    <Select2
                      name="reading"
                      v-model="profile.profile.reading"
                      :options="testTypeScores"
                      @change="calculateOverAll($event)"
                      @select="calculateOverAll($event)"
                    />
                  </div>

                  <div class="form-group" v-if="profile.haveEnglishTestScore">
                    <p class="settings-label">Overall Score</p>
                    {{ profile.ieltsOverallScore }}
                  </div>

                  <div class="form-group">
                    <p class="settings-label">
                      Study Level <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="enqType"
                      v-model="profile.enqType"
                      :options="studyLevels"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </Form>
          </div>

          <div class="tab-pane fade" id="country_preference">
            <Form @submit="onSubmit('country_preference')">
              <div class="form-group">
                <p class="settings-label">Yet need to be decided?</p>
                <input
                  name="preferenceNeedDecided"
                  type="checkbox"
                  v-model="profile.preferenceNeedDecided"
                />
              </div>
              <div class="row" v-if="profile.preferenceNeedDecided">
                <div class="col-md-4">
                  <div class="form-group">
                    <p class="settings-label">
                      Country <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="countryId"
                      @select="loadCities($event)"
                      v-model="countryId"
                      :options="countriesArr"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <p class="settings-label">
                      City <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="cityId"
                      v-model="cityId"
                      @select="selectCities($event)"
                      :options="citiesArr"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <p class="settings-label">&nbsp;&nbsp;&nbsp;</p>
                    <button
                      type="button"
                      class="btn btn-success"
                      @click="addToPreference()"
                    >
                      Add To Preference
                    </button>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div class="table-responsive">
                    <table
                      class="table border-0 star-student table-hover table-center mb-0 datatable table-striped"
                    >
                      <thead class="student-thread">
                        <th>Country</th>
                        <th>City</th>
                        <th class="text-end">Action</th>
                      </thead>
                      <tbody>
                        <tr
                          v-if="
                            profile.totalPreference &&
                            profile.totalPreference.length
                          "
                          v-for="(preference, index) in profile.totalPreference"
                          :key="index"
                        >
                          <td>
                            <h2>
                              {{ preference.country }}
                            </h2>
                          </td>
                          <td>{{ preference.city }}</td>
                          <td class="text-end">
                            <div class="actions">
                              <a
                                href="javascript:;"
                                @click="removePreference(index)"
                                class="btn btn-sm bg-danger-light"
                              >
                                <i class="feather-trash"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </Form>
          </div>

          <div class="tab-pane fade" id="academic_details">
            <Form @submit="onSubmit('academic_details')">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <p class="settings-label">
                      Qualification <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="qualificationType"
                      id="qualificationType"
                      v-model="qualificationType"
                      @select="selectQualification($event)"
                      :options="qualifications"
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-if="qualificationType">
                <div class="col-md-4">
                  <div class="form-group">
                    <p class="settings-label">
                      Passing Year <span class="star-red">*</span>
                    </p>
                    <Field
                      type="number"
                      name="passingYear"
                      v-model="passingYear"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <p class="settings-label">
                      % or Grade <span class="star-red">*</span>
                    </p>
                    <Field
                      type="number"
                      name="grade"
                      v-model="grade"
                      class="form-control"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="['X', 'XII'].includes(qualificationType)"
                >
                  <div class="form-group">
                    <p class="settings-label">
                      Math Score <span class="star-red">*</span>
                    </p>
                    <Field
                      type="number"
                      name="mathScore"
                      v-model="mathScore"
                      class="form-control"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="['X', 'XII'].includes(qualificationType)"
                >
                  <div class="form-group">
                    <p class="settings-label">
                      English Score <span class="star-red">*</span>
                    </p>
                    <Field
                      type="number"
                      name="englishScore"
                      v-model="englishScore"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-4" v-if="!['X'].includes(qualificationType)">
                  <div class="form-group">
                    <p class="settings-label">
                      Major Subjects <span class="star-red">*</span>
                    </p>
                    <tags-input
                      element-id="majorSubject"
                      v-model="majorSubject"
                      @tag-added="onTagAdded"
                      @tag-removed="onTagRemoved"
                      :typeahead="true"
                    ></tags-input>
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="
                    ['Diploma', 'Bachelor Degree', 'Post Graduation'].includes(
                      qualificationType
                    )
                  "
                >
                  <div class="form-group">
                    <p class="settings-label">
                      Backlogs <span class="star-red">*</span>
                    </p>
                    <Field
                      type="number"
                      name="backlogs"
                      v-model="backlogs"
                      class="form-control"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="
                    ['Diploma', 'Bachelor Degree', 'Post Graduation'].includes(
                      qualificationType
                    )
                  "
                >
                  <div class="form-group">
                    <p class="settings-label">
                      Type Of Degree <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="degree"
                      v-model="degree"
                      :options="typeOfDegreeOptions"
                    />
                  </div>
                </div>
                <div class="col-md-4" v-if="!['X'].includes(qualificationType)">
                  <div class="form-group">
                    <p class="settings-label">
                      Streams <span class="star-red">*</span>
                    </p>
                    <Select2
                      name="stream"
                      v-model="stream"
                      :options="streamOptions"
                      :settings="{ multiple: true }"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <p class="settings-label">&nbsp;&nbsp;</p>
                    <button
                      type="button"
                      @click.prevent="saveQualifiation()"
                      class="btn btn-success"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <div class="table-responsive">
                    <table
                      class="table border-0 star-student table-hover table-center mb-0 datatable table-striped"
                    >
                      <thead class="student-thread">
                        <th>Qualification</th>
                        <th>Passing Year</th>
                        <th>Percentage</th>
                        <th>Maths</th>
                        <th>English</th>
                        <th>Stream</th>
                        <th>Major Subjects</th>
                        <th>Backlogs</th>
                        <th>Degree Type</th>
                        <th class="text-end">Action</th>
                      </thead>
                      <tbody>
                        <tr
                          v-if="
                            profile.profile &&
                            profile.profile.newQualification &&
                            profile.profile.newQualification.length
                          "
                          v-for="(newQualification, index) in profile.profile
                            .newQualification"
                          :key="index"
                        >
                          <td>
                            <h2>
                              {{ newQualification.qualification }}
                            </h2>
                          </td>
                          <td>{{ newQualification.passingYear }}</td>
                          <td>{{ newQualification.percentage }}</td>
                          <td>{{ newQualification.maths }}</td>
                          <td>{{ newQualification.english }}</td>
                          <td>
                            {{
                              newQualification.stream
                                ? newQualification.stream.join(", ")
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              newQualification.major
                                ? newQualification.major.join(", ")
                                : ""
                            }}
                          </td>
                          <td>{{ newQualification.backlogs }}</td>
                          <td>{{ newQualification.degreeType }}</td>
                          <td class="text-end">
                            <div class="actions">
                              <a
                                href="javascript:;"
                                @click="removeQualification(index)"
                                class="btn btn-sm bg-danger-light"
                              >
                                <i class="feather-trash"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { notify } from "@kyvg/vue3-notification";
import Request, { API, siteURL } from "../../request";
import { router } from "../../router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { ref } from "vue";

export default {
  components: {
    Form,
    Field,
  },
  data: () => {
    return {
      enquiryTypes: [
        {
          id: "COACHING",
          text: "COACHING",
        },
        {
          id: "STUDENT",
          text: "Study Abroad",
        },
      ],
      genderOptions: ["Male", "Female"],
      maritalStatusOptions: ["Married", "Unmarried", "Other"],
      modeOptions: [
        "Conopy",
        "Education Fair",
        "Facebook",
        "Google",
        "Instagram",
        "Justdial",
        "Newspaper",
        "Seminar",
        "School/College Event",
        "Telephone",
        "Website",
        "Whatsapp",
        "Friend Reference",
      ],
      accompaniedByOptions: ["No", "Yes"],
      testTypeOptions: [],
      qualifications: [
        "X",
        "XII",
        "Diploma",
        "Bachelor Degree",
        "Post Graduation",
      ],
      studyLevels: ["Under Graduate", "Post Graduate", "Schooling"],
      grade: "",
      mathScore: "",
      englishScore: "",
      majorSubjectOptions: [],
      backlogs: "",
      typeOfDegreeOptions: [],
      majorSubject: [],
      streamOptions: ["MEDICAL", "NON-MEDICAL", "COMMERCE", "ARTS"],
      typeofDegree: {
        Diploma: [
          {
            id: "Diploma Of Engineering",
            text: "Diploma Of Engineering",
          },
          {
            id: "Diploma Of Management",
            text: "Diploma Of Management",
          },
        ],
        "Bachelor Degree": [
          {
            id: "Bachelor of Agriculture",
            text: "Bachelor of Agriculture",
          },
          {
            id: "Bachelor of Arts",
            text: "Bachelor of Arts",
          },
          {
            id: "Bachelor of Ayurvedic Medicine & Surgery - BAMS",
            text: "Bachelor of Ayurvedic Medicine & Surgery - BAMS",
          },
          {
            id: "Bachelor of Business Administration",
            text: "Bachelor of Business Administration",
          },
          {
            id: "Bachelor of Commerce",
            text: "Bachelor of Commerce",
          },
          {
            id: "Bachelor of Computer Applications",
            text: "Bachelor of Computer Applications",
          },
          {
            id: "Bachelor of Computer Science",
            text: "Bachelor of Computer Science",
          },
          {
            id: "Bachelor of Dental Surgery - BDS",
            text: "Bachelor of Dental Surgery - BDS",
          },
          {
            id: "Bachelor of Design",
            text: "Bachelor of Design",
          },
          {
            id: "Bachelor of Education",
            text: "Bachelor of Education",
          },
          {
            id: "Bachelor of Engineering",
            text: "Bachelor of Engineering",
          },
          {
            id: "Bachelor of Fine Arts",
            text: "Bachelor of Fine Arts",
          },
          {
            id: "Bachelor of Home Science",
            text: "Bachelor of Home Science",
          },
          {
            id: "Bachelor of Homeopathic Medicine and Surgery - BHMS",
            text: "Bachelor of Homeopathic Medicine and Surgery - BHMS",
          },
          {
            id: "Bachelor of Laws",
            text: "Bachelor of Laws",
          },
          {
            id: "Bachelor of Mass Communications",
            text: "Bachelor of Mass Communications",
          },
          {
            id: "Bachelor of Medicine and Bachelor of Surgery (MBBS)",
            text: "Bachelor of Medicine and Bachelor of Surgery (MBBS)",
          },
          {
            id: "Bachelor of Nursing",
            text: "Bachelor of Nursing",
          },
          {
            id: "Bachelor of Pharmacy",
            text: "Bachelor of Pharmacy",
          },
          {
            id: "Bachelor of Physiotherapy",
            text: "Bachelor of Physiotherapy",
          },
          {
            id: "Bachelor of Science",
            text: "Bachelor of Science",
          },
          {
            id: "Bachelor of Social Work",
            text: "Bachelor of Social Work",
          },
          {
            id: "Bachelor of Technology",
            text: "Bachelor of Technology",
          },
          {
            id: "Bachelor of Veterinary Science & Animal Husbandry",
            text: "Bachelor of Veterinary Science & Animal Husbandry",
          },
          {
            id: "Doctor of Medicine",
            text: "Doctor of Medicine",
          },
          {
            id: "Doctor of Medicine in Homoeopathy",
            text: "Doctor of Medicine in Homoeopathy",
          },
          {
            id: "Doctor of Pharmacy",
            text: "Doctor of Pharmacy",
          },
          {
            id: "Doctorate of Medicine",
            text: "Doctorate of Medicine",
          },
          {
            id: "General Nursing & Midwifery (GNM)",
            text: "General Nursing & Midwifery (GNM)",
          },
          {
            id: "Bachelor of Journalism",
            text: "Bachelor of Journalism",
          },
          {
            id: "ITI",
            text: "ITI",
          },
          {
            id: "Other",
            text: "Other",
          },
        ],
        masters: [
          {
            id: "Master of Agriculture",
            text: "Master of Agriculture",
          },
          {
            id: "Master of Arts",
            text: "Master of Arts",
          },
          {
            id: "Master of Ayurvedic Medicine & Surgery - BAMS",
            text: "Master of Ayurvedic Medicine & Surgery - BAMS",
          },
        ],
      },
      passingYear: "",
      degree: "",
      stream: [],
      qualificationType: "",
      countriesArr: [],
      citiesArr: [],
      countryId: "",
      cityId: "",
      countryName: "",
      cityName: "",
      profile: {
        selectInquiryType: "STUDENT",
        profile: {
          address: "",
        },
        applicantAppliedAnyTypeImmigrationCountryIdSwitch: false,
        applicantLivingStudyingAnyOtherCountrySwitch: false,
        applicantCitizenMoreCountrySwitch: false,
        applicantVisaRefusalCountryIdSwitch: false,
      },
      title: "",
      description: "",
      files: "",
      siteURL: siteURL,
      dateOfBirth: null,
      activeTab: "basicInfo",
      phoneCodes: [],
      myOptions: {
        layout: {
          color: "black",
          backgroundColor: "lightgray",
          selectedColor: "white",
          selectedBackgroundColor: "green",
          borderColor: "black",
          fontFamily: "Arial",
          fontWeight: "normal",
          fontWeightSelected: "bold",
          squareCorners: false,
          noBorder: false,
        },
        size: {
          fontSize: 14,
          height: 34,
          padding: 7,
          width: 100,
        },
        config: {
          delay: 0.4,
          preSelected: "unknown",
          disabled: false,
          items: [
            {
              name: "Off",
              value: "Off",
              color: "white",
              backgroundColor: "red",
            },
            {
              name: "On",
              value: "On",
              color: "white",
              backgroundColor: "green",
            },
          ],
        },
      },
      testTypeArray: [],
      testTypeScores: [],
    };
  },
  async beforeMount() {
    await this.loadCountries();
    await this.getAllTestTypes();
    await this.getProfile();
  },
  methods: {
    getTestTypeScores(event) {
      let testType = this.profile.testType;
      let testTypeObject =
        this.testTypeArray[testType] != undefined
          ? this.testTypeArray[testType]
          : {};
      let testTypeScore = [];
      if (testTypeObject.endNumber) {
        for (
          let index = testTypeObject.startNumber;
          index <= testTypeObject.endNumber;
          index += testTypeObject.numberStep
        ) {
          testTypeScore.push(index);
        }
      }
      this.testTypeScores = testTypeScore;
    },
    calculateOverAll() {
      let totalSum = 0;
      let { listening, writing, reading, speaking } = this.profile.profile;

      if (listening > 0) {
        totalSum += parseFloat(listening);
      }
      if (writing > 0) {
        totalSum += parseFloat(writing);
      }
      if (reading > 0) {
        totalSum += parseFloat(reading);
      }
      if (speaking > 0) {
        totalSum += parseFloat(speaking);
      }

      let ieltsOverallScore = 0;
      if (totalSum >= 11) {
        let data = 0;
        totalSum = Math.floor(totalSum);
        if (totalSum % 2 != 0) {
          data = totalSum + 1;
        } else {
          data = totalSum;
        }
        ieltsOverallScore = parseFloat(data / 4).toFixed(1);
      }

      this.profile.ieltsOverallScore = ieltsOverallScore;
    },
    async getAllTestTypes() {
      let response = await Request.getRequest(
        "testTypesDetails?results=100&sortField=countryName&sortOrder=ascend"
      );
      if (response && response.data) {
        let testTypeOptions = [];
        let testTypeArray = [];
        response.data.map((testTypeData) => {
          testTypeOptions.push(testTypeData.testType);
          if (testTypeArray[testTypeData.testType] == undefined) {
            testTypeArray[testTypeData.testType] = {};
          }
          testTypeArray[testTypeData.testType] = testTypeData;
        });
        this.testTypeOptions = testTypeOptions;
        this.testTypeArray = testTypeArray;
      }
    },

    async upload(event) {
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file);

      const response = await Request.postRequest("filesUploader", formData);
      this.files = response;
    },
    async uploadProfilePic(event) {
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file);

      const response = await Request.postRequest("filesUploader", formData);
      if (response) {
        const profilePicData = {
          profilePic: [
            {
              status: "done",
              type: response.mimetype,
              uid: "rc-upload-" + this.profile._id + "-2",
              name: response.originalname,
              size: response.size,
              response: response,
              originFileObj: {
                uid: "rc-upload-" + this.profile._id + "-2",
                type: response.mimetype,
                name: response.originalname,
                size: response.size,
              },
            },
          ],
          _id: this.profile._id,
        };
        const result = await Request.postRequest(
          "studentPanel/updateProfilePic",
          profilePicData
        );
        if (response.error === true) {
          notify({
            type: "warn",
            title: result.message,
          });
        } else {
          notify({
            type: "success",
            title: result.message,
          });
          await this.getProfile();
        }
      }
    },
    countryOnChange(input) {
      this.profile.countryCode = input.countryPhoneCode;
    },
    selectQualification(value) {
      if (value.id == "Diploma") {
        this.typeOfDegreeOptions = this.typeofDegree["Diploma"];
      } else if (value.id == "Bachelor Degree") {
        this.typeOfDegreeOptions = this.typeofDegree["Bachelor Degree"];
      } else if (value.id == "Post Graduation") {
        this.typeOfDegreeOptions = this.typeofDegree["masters"];
      } else {
        this.typeOfDegreeOptions = [];
      }
      this.passingYear = "";
      this.grade = "";
      this.mathScore = "";
      this.englishScore = "";
      this.stream = [];
      this.majorSubject = [];
      this.backlogs = "";
      this.degree = "";
    },
    onTagAdded(slug) {
      this.majorSubject.push(slug.value);
    },
    onTagRemoved(slug) {
      var index = this.majorSubject.indexOf(slug.value);
      if (index !== -1) {
        this.majorSubject.splice(index, 1);
      }
    },
    openLastTab(tab) {
      this.activeTab = tab;
    },
    async loadCountries() {
      const response = await Request.getRequest(
        "countryWithoutAuth?results=100&sortField=countryName&sortOrder=ascend"
      );
      this.countriesArr = [];
      this.phoneCodes = [];
      if (response && response.data && response.data.length > 0) {
        for (let index in response.data) {
          let country = response.data[index];
          if (country.countryPhoneCode) {
            this.phoneCodes.push(country.countryPhoneCode);
          }
          this.countriesArr.push({
            id: country._id,
            text: country.countryName,
            countryPhoneCode: country.countryPhoneCode,
          });
        }
      }
    },
    async loadCities(value) {
      this.countryName = value.text;
      const response = await Request.getRequest(
        `/country/allCities/${this.countryId}`
      );
      this.citiesArr = [];
      if (response && response.result && response.result.length > 0) {
        for (let index in response.result) {
          let city = response.result[index];
          this.citiesArr.push({
            id: city._id,
            text: city.cityName,
          });
        }
      }
    },
    selectCities(value) {
      this.cityName = value.text;
    },
    addToPreference() {
      this.profile.totalPreference.push({
        country: this.countryName,
        city: this.cityName,
      });
    },
    removePreference(index) {
      let totalPreference = this.profile.totalPreference;
      if (
        totalPreference &&
        totalPreference.length > 0 &&
        totalPreference[index] != undefined
      ) {
        totalPreference.splice(index, 1);
      }
      this.profile.totalPreference = totalPreference;
    },
    saveQualifiation() {
      this.profile.profile.newQualification.push({
        qualification: this.qualificationType,
        passingYear: this.passingYear,
        percentage: this.grade,
        maths: this.mathScore,
        english: this.englishScore,
        stream: this.stream,
        major: this.majorSubject,
        backlogs: this.backlogs,
        degreeType: this.degree,
      });
      this.qualificationType = "";
      this.passingYear = "";
      this.grade = "";
      this.mathScore = "";
      this.englishScore = "";
      this.stream = [];
      this.majorSubject = [];
      this.backlogs = "";
      this.degree = "";
    },
    removeQualification(index) {
      let newQualification = this.profile.profile.newQualification;
      if (
        newQualification &&
        newQualification.length > 0 &&
        newQualification[index] != undefined
      ) {
        newQualification.splice(index, 1);
      }
      this.profile.profile.newQualification = newQualification;
    },
    async upload(event) {
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file);

      const response = await Request.postRequest("filesUploader", formData);
      this.files = response;
    },
    async onSubmit(tab) {
      if(tab == 'inquiry') {
        if (!this.profile.studentName) {
          notify({
            type: "warn",
            title: "Student name is required.",
          });
          return false;
        } else if (!this.profile.gender || this.profile.gender == undefined) {
          notify({
            type: "warn",
            title: "Gender is required.",
          });
          return false;
        } else if (
          !this.profile.maritalStatus ||
          this.profile.maritalStatus == undefined
        ) {
          notify({
            type: "warn",
            title: "Marital Status is required.",
          });
          return false;
        } else if (
          !this.profile.addressCountryId ||
          this.profile.addressCountryId == undefined
        ) {
          notify({
            type: "warn",
            title: "Country is required.",
          });
          return false;
        } else if (
          !this.profile.countryCode ||
          this.profile.countryCode == undefined
        ) {
          notify({
            type: "warn",
            title: "Country Code is required.",
          });
          return false;
        } else if (!this.profile.mobile || this.profile.mobile == undefined) {
          notify({
            type: "warn",
            title: "Mobile no is required.",
          });
          return false;
        } else if (!this.profile.address || this.profile.address == undefined) {
          notify({
            type: "warn",
            title: "Address is required.",
          });
          return false;
        } else if (!this.profile.state || this.profile.state == undefined) {
          notify({
            type: "warn",
            title: "State is required.",
          });
          return false;
        } else if (!this.profile.city || this.profile.city == undefined) {
          notify({
            type: "warn",
            title: "City is required.",
          });
          return false;
        } else if (!this.profile.pincode || this.profile.pincode == undefined) {
          notify({
            type: "warn",
            title: "Pincode is required.",
          });
          return false;
        }
      } else if(tab == "passport") {
        if (!this.profile.profile.passportNumber || this.profile.profile.passportNumber == undefined) {
          notify({
            type: "warn",
            title: "Passport no is required.",
          });
          return false;
        } else if (!this.profile.expiryDate || this.profile.expiryDate == undefined) {
          notify({
            type: "warn",
            title: "Expiry Date is required.",
          });
          return false;
        } else if (!this.profile.issueDate || this.profile.issueDate == undefined) {
          notify({
            type: "warn",
            title: "Issue Date is required.",
          });
          return false;
        } else if (!this.profile.issueCountryId || this.profile.issueCountryId == undefined) {
          notify({
            type: "warn",
            title: "Issued Country is required.",
          });
          return false;
        }
      } else if (tab == "english_proficiency") {
        if (!this.profile.whichYearPlanningToGo) {
          notify({
            type: "warn",
            title: "Please select which year you planning to go.",
          });
          return false;
        } else if (this.profile.haveEnglishTestScore) {
          if (!this.profile.testType) {
            notify({
              type: "warn",
              title: "Please select your test type.",
            });
            return false;
          } else if (!this.profile.profile.speaking) {
            notify({
              type: "warn",
              title: "Please select speaking score.",
            });
            return false;
          } else if (!this.profile.profile.listening) {
            notify({
              type: "warn",
              title: "Please select listening score.",
            });
            return false;
          } else if (!this.profile.profile.writing) {
            notify({
              type: "warn",
              title: "Please select writing score.",
            });
            return false;
          } else if (!this.profile.profile.reading) {
            notify({
              type: "warn",
              title: "Please select reading score.",
            });
            return false;
          }
        } else if (!this.profile.enqType) {
          notify({
            type: "warn",
            title: "Please select study level.",
          });
          return false;
        }
      } else if (tab == 'country_preference') {
        if (
          this.profile.totalPreference.length == 0 &&
          this.profile.preferenceNeedDecided
        ) {
          notify({
            type: "warn",
            title:
              "Add at least one where you may be interested or multiple universities you can add one by one.",
          });
          return false;
        }
      } else if (tab == "qualification") {
        if (
          this.profile.profile &&
          (this.profile.profile.newQualification.length == 0 ||
            this.profile.profile.newQualification == undefined)
        ) {
          notify({
            type: "warn",
            title: "Add at least on qualification.",
          });
          return false;
        }
      }
      console.log(this.profile)
      return false;
      const response = await Request.postRequest(
        "studentPanel/updateProfile",
        this.profile
      );
      if (response.error === true) {
        notify({
          type: "warn",
          title: response.message,
        });
      } else {
        document.getElementById("updateProfileForm").reset();
        notify({
          type: "success",
          title: response.message,
        });
        router.push("/profile/edit");
      }
    },
    async getProfile() {
      const response = await Request.getRequest("studentPanel/getProfile");
      if (response.error === false) {
        this.profile = response.doc;
        if (this.profile.profile && this.profile.profile.address == undefined) {
          this.profile.profile.address = "";
        }
        if (this.profile.location == undefined) {
          this.profile.location = "Direct";
        }
        if (this.profile.reading == undefined) {
          this.profile.reading = "";
        }
        if (this.profile.writing == undefined) {
          this.profile.writing = "";
        }
        if (this.profile.listening == undefined) {
          this.profile.listening = "";
        }
        if (this.profile.speaking == undefined) {
          this.profile.speaking = "";
        }
        if (
          this.profile.totalPreference == undefined &&
          !this.profile.totalPreference
        ) {
          this.profile.totalPreference = [];
        }
        if (
          this.profile.profile.newQualification == undefined &&
          !this.profile.profile.newQualification
        ) {
          this.profile.profile.newQualification = [];
        }
        this.profile.selectInquiryType = "STUDENT";
        this.dateOfBirth = ref(this.profile.dateOfBirth);
        this.getTestTypeScores(this);
      }
    },
  },
};
</script>
<style>
.antd-pro-containers-student-inquiry-add-style-infoBox1 {
  margin-top: 1px;
  justify-self: center;
  align-self: center;
  background-color: #ffad49;
  padding: 20px;
  color: white;
  font-size: 12px;
  font-size: 13px;
}
.tab-header {
  background-color: #ffad49;
  padding: 10px;
  color: #fff;
  margin-top: -1px;
  margin-left: 0px;
  border-radius: 3px 3px 0px 0px;
  width: 100.1%;
  cursor: pointer;
}
#updateProfileForm .card-body {
  padding: 5px 15px;
}
#updateProfileForm .card-body .card {
  margin-bottom: 5px;
}
.tags-input-badge {
  position: relative;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tags-input-badge-pill {
  padding-right: 1.25em;
  padding-left: 0.6em;
  border-radius: 10em;
}
.tags-input-badge-selected-default {
  color: #212529;
  background-color: #f0f1f2;
}
.tags-input span {
  margin-right: 0.3em;
}
.tags-input span {
  margin-right: 0.3em;
}
.tags-input-remove {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  right: 0.3em;
  top: 0.3em;
  padding: 0.5em;
  overflow: hidden;
}
.tags-input-remove:before,
.tags-input-remove:after {
  content: "";
  position: absolute;
  width: 75%;
  left: 0.15em;
  background: #5dc282;
  height: 2px;
  margin-top: -1px;
}
.tags-input-remove:before {
  transform: rotate(45deg);
}
.tags-input-remove:after {
  transform: rotate(-45deg);
}
.tags-input input {
  flex: 1;
  background: transparent;
  border: none;
}
.tags-input input[type="text"] {
  color: #495057;
}
.select2-container {
  display: inline !important;
}
</style>
