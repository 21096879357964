<template>
    <!-- Logo -->
    <div class="header-left">
        <router-link to="/dashboard" class="logo">
            <img src="../assets/img/TenAgents-logo.png" alt="Logo">
        </router-link>
        <router-link to="/dashboard" class="logo logo-small">
            <img src="../assets/img/TenAgents-logo.png" alt="Logo" width="30" height="30">
        </router-link>
    </div>
    <!-- /Logo -->
</template>