<template>    
	<!-- Main Wrapper -->
	<div class="main-wrapper login-body">
		<div class="login-wrapper">
			<div class="container">
				<div class="loginbox">
					<div class="login-left">
						<img class="img-fluid" src="../../assets/img/logo.png" alt="Logo">
					</div>
					<div class="login-right">
						<div class="login-right-wrap">
							<h1>Register</h1>
							
							<!-- register Form -->
							<Form class="login" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
								<div class="form-group">
									<p class="settings-label">Name <span class="star-red">*</span></p>
									<Field name="studentName" type="text" v-model="studentName" class="form-control" :class="{ 'is-invalid': errors.studentName }" />
									<div class="invalid-feedback">{{errors.studentName}}</div>
									<div class="emailshow text-danger" id="studentName"></div>
								</div>
                                <div class="form-group">
									<p class="settings-label">Email</p>
									<Field name="emailId" type="email" v-model="emailId" class="form-control" :class="{ 'is-invalid': errors.emailId }" />
									<div class="invalid-feedback">{{errors.emailId}}</div>
									<div class="emailshow text-danger" id="emailId"></div>
								</div>
                                <div class="form-group">
									<p class="settings-label">Phone <span class="star-red">*</span></p>
									<Field name="mobile" type="text" v-model="mobile" class="form-control" :class="{ 'is-invalid': errors.mobile }" />
									<div class="invalid-feedback">{{errors.mobile}}</div>
									<div class="emailshow text-danger" id="mobile"></div>
								</div>
                                <div class="form-group">
									<p class="settings-label">Whats App <span class="star-red">*</span></p>
									<Field name="whatsApp" type="text" v-model="whatsApp" class="form-control" :class="{ 'is-invalid': errors.whatsApp }" />
									<div class="invalid-feedback">{{errors.whatsApp}}</div>
									<div class="emailshow text-danger" id="whatsApp"></div>
								</div>
                                <div class="form-group" v-if="otpForm">
									<p class="settings-label">OTP Code <span class="star-red">*</span></p>
									<Field name="otp" type="text" v-model="otp" class="form-control" :class="{ 'is-invalid': errors.otp }" />
									<div class="invalid-feedback">{{errors.otp}}</div>
									<div class="emailshow text-danger" id="otp"></div>
								</div>
								<div class="form-group text-center" v-if="!otpForm">
									<button class="btn btn-primary btn-block" type="submit" value="generateOTP">Generate OTP</button>
								</div>
								<div class="form-group text-center row" v-if="otpForm">
                                    <div class="col-md-6">
                                        <button class="btn btn-primary btn-block" type="button" @click.prevent="resendOTP" value="resendOTP">Resend</button>
                                    </div>
                                    <div class="col-md-6">
                                        <button class="btn btn-primary btn-block" type="submit" value="register">Register</button>
                                    </div>									
								</div>
							</Form>
							<!-- /register Form -->
							<div class="text-center forgotpass"><router-link to="/">Login</router-link></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- /Main Wrapper -->		
</template>
<script>
	import { notify } from "@kyvg/vue3-notification"
	import {API} from '../../request'
	import { router } from '../../router';
	import { Form, Field } from 'vee-validate';
	import * as Yup from 'yup';
	export default {
	  	components: {
			Form,
			Field,
		},
		data: () => {
			return {
				otpForm: false,
				studentName:'',
				emailId:'',
				mobile:'',
                whatsApp: '',
				otp:''
			}
		},
		mounted() {
		},
		methods: {
			onSubmit() {
				document.getElementById("studentName").innerHTML = ""
                document.getElementById("emailId").innerHTML = ""
                document.getElementById("mobile").innerHTML = ""
                document.getElementById("whatsApp").innerHTML = ""
				let dataString = {
					studentName : this.studentName,
                    emailId : this.emailId,
                    mobile : this.mobile,
                    watsupp: this.whatsApp,
                    otp : this.otp
				}
				const $this = this
				API.post('studentPanel/doRegister', dataString)
				.then((result)=>{
					if(result.data.error === true) {
						notify({
							type: 'warn',
							title: result.data.message
						});
					} else {						
						notify({
							type: 'success',
							title: result.data.message
						});
                        if(result.data.data && result.data.data.otp == true) {
                            $this.otpForm = result.data.data.otp;
                        } else {
                            localStorage.setItem('token', result.data.token);
                            localStorage.setItem('user', JSON.stringify(result.data.user));
                            router.push('/profile/edit')
                        }
					}
				}).catch((error) => {
					console.warn(error);
				})
			},
            resendOTP() {
                this.otp = '';
                this.onSubmit();
            }
		},	
		setup() {
		 	const schema = Yup.object().shape({
				studentName: Yup.string().required('Name is required'),
                emailId: Yup.string().email('Email is invalid'),
                mobile: Yup.string().required('Mobile is required'),
                whatsApp: Yup.string().required('Whats App is required')
			});			
			return {
				schema
			};	
		}	
	}
</script>