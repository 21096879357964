<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <!-- Page Header -->
        <div class="page-header">
          <div class="row">
            <div class="col">
              <h3 class="page-title">My Applications</h3>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/profile/edit">Profile</router-link>
                </li>
                <li class="breadcrumb-item active">My Applications</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Page Header -->
        <div class="tab-content profile-tab-cont">
          
          <div class="tab-pane fade show active" id="all_applications">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-striped table-bordered" v-if="(profile.atLevel == 'Front Desk' || profile.atLevel == 'Registration Desk' || profile.atLevel == 'Operation Manager Desk') && profile.counsellingDesk && profile.counsellingDesk.course.length > 0">
                  <thead>
                    <tr>
                      <th>University</th>
                      <th>Course</th>
                      <th>Campus</th>
                      <th>Duration</th>
                      <th>Tution Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr 
                      v-for="(counsellingDesk, index) in profile.counsellingDesk.course"
                      :key="index"
                      >
                      <td><small>{{ counsellingDesk.universityInstitutionName }}</small></td>
                      <td><small>{{ counsellingDesk.courseName }}</small></td>
                      <td><small>{{ counsellingDesk.campusName }}</small></td>
                      <td><small>{{ counsellingDesk.courseDuration }}</small></td>
                      <td><small>{{ counsellingDesk.tutionFee }}</small></td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-striped table-bordered" v-if="(profile.atLevel == 'Application Desk' || profile.atLevel == 'Visa Desk' || profile.atLevel == 'Claim Desk') && profile.applicationsDesk && profile.applicationsDesk.course.length > 0">
                  <thead>
                    <tr>
                      <th>University</th>
                      <th>Course</th>
                      <th>Intake</th>
                      <th>Offer Status</th>
                      <th>Course Start Date</th>
                      <th>Institute ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr 
                      v-for="(applicationsDesk, index) in profile.applicationsDesk.course"
                      :key="index"
                      >
                      <td><small>{{ applicationsDesk.universityInstitutionName }}</small></td>
                      <td><small>{{ applicationsDesk.courseName }}</small></td>
                      <td><small>{{ applicationsDesk.intake }}-{{ applicationsDesk.intakeYear }}</small></td>
                      <td><small>{{ applicationsDesk.stage2.offerStatus }}</small></td>
                      <td><small>{{ YYYYMMDDSTRING(applicationsDesk.stage2.courseStartDate) }}</small></td>
                      <td><small>{{ applicationsDesk.stage2.instituteStudentId }}</small></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import Request, { API, siteURL } from "../../request";
import { Form, Field } from "vee-validate";

export default {
  data: () => {
    return {
      profile: {}
    };
  },
  async beforeMount() {
    await this.getProfile();
  },
  methods: {
    YYYYMMDDSTRING(value) {
      if(value == undefined) {
        return value;
      }
      const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      var currentdate = new Date(value);
      var date = (months[currentdate.getMonth()])+' '+((currentdate.getDate() < 10)?"0":"") + currentdate.getDate() +" "+ currentdate.getFullYear();
      return date;
    },
    async getProfile() {
      const response = await Request.getRequest("studentPanel/getProfile");
      if (response.error === false) {
        this.profile = response.doc;
      }
    },
  },
};
</script>
