<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>
    <div class="page-wrapper">
      
      <div class="content container-fluid">
        <!-- Page Header -->
        <div class="page-header">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="page-title">Welcome {{user.name}}!</h3>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">File Details Below</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tab-content profile-tab-cont" style="padding-bottom: 50px;">
          
          <div class="tab-pane fade show active" id="all_applications">
            <div class="col md-6" >
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <th>Registration ID</th>
                      <td>{{ profile.id }}</td>
                    </tr>
                    <tr>
                      <th>Student Name</th>
                      <td>{{ profile.studentName }}</td>
                    </tr>
                    <tr>
                      <th>Mobile</th>
                      <td>{{ profile.mobile }}</td>
                    </tr>
                    <tr>
                      <th>DOB</th>
                      <td>{{ YYYYMMDDSTRING(profile.dateOfBirth) }}</td>
                    </tr>
                    <tr>
                      <th>Passport No.</th>
                      <td>{{ visaDesk.passportNo }}</td>
                    </tr>
                    <tr>
                      <th>File Status</th>
                      <td>{{ visaDesk.fileStatus }}</td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td>{{ visaDesk.selectedCourse ? visaDesk.selectedCourse.countryName : 'NA' }}</td>
                    </tr>
                    <tr>
                      <th>Universities</th>
                      <td>{{ visaDesk.selectedCourse ? visaDesk.selectedCourse.universityInstitutionName : 'NA' }}</td>
                    </tr>
                    <tr>
                      <th>Course</th>
                      <td>{{ visaDesk.selectedCourse ? visaDesk.selectedCourse.courseName : 'NA' }}</td>
                    </tr>
                    <tr>
                      <th>Course Start Date</th>
                      <td>{{ YYYYMMDDSTRING(visaDesk.courseStartDate) }}</td>
                    </tr>
                    <tr>
                      <th>Institute Id</th>
                      <td>{{ visaDesk.selectedCourse ? visaDesk.selectedCourse.instituteStudentId : 'NA' }}</td>
                    </tr>
                    <tr>
                      <th>Visa Submission Date</th>
                      <td>{{ YYYYMMDDSTRING(visaDesk.submissionDate) }}</td>
                    </tr>
                    <tr>
                      <th>Visa Arrival Date</th>
                      <td>{{ YYYYMMDDSTRING(visaDesk.arrivalDate) }}</td>
                    </tr>
                    <tr>
                      <th>Visa Approval Date</th>
                      <td>{{ YYYYMMDDSTRING(visaDesk.visaApprovalDate) }}</td>
                    </tr>
                    <tr>
                      <th>Visa Status</th>
                      <td>{{ visaDesk.visaStatus }}</td>
                    </tr>
                    <tr>
                      <th>File No.</th>
                      <td>{{ visaDesk.fileNo }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>     
    </div>          
  </div>  	
</template>

<script>
import Request from "../../request";
import "vue3-circle-progress/dist/circle-progress.css";

export default {
	components: {    
	},
  data() {
    return {
      user: {},
      profile:{},
      visaDesk: {}
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getUserDetails()
  },
  methods:{
    YYYYMMDDSTRING(value) {
      if(value == undefined) {
        return value;
      }
      const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      var currentdate = new Date(value);
      var date = (months[currentdate.getMonth()])+' '+((currentdate.getDate() < 10)?"0":"") + currentdate.getDate() +" "+ currentdate.getFullYear();
      return date;
    },
    async getUserDetails() {
      const response = await Request.getRequest('studentPanel/getProfile');
      if(response.error === false) {
        this.profile = response.doc;
        this.visaDesk = response.doc.visaDesk;
      }
    }
  }
}
</script>