<template>
  <div id="app">
    <loader></loader>
    <notifications position="top right" />
    <router-view/>
  </div>
</template>

<script>
import Loader from './views/Loader'
export default {
  name: 'App',
  components: {
    Loader
  },
  mounted() {
     // minimize mobile menu button
        $(document).on('click', '#toggle_btn', function() {
            if($('body').hasClass('mini-sidebar')) {
                $('body').removeClass('mini-sidebar');
                $('.subdrop + ul').slideDown();
            } else {
                $('body').addClass('mini-sidebar');
                $('.subdrop + ul').slideUp();
            }
            return false;
        });

  }
}
</script>
<style>
.mini-sidebar .sidebar .sidebar-menu ul > li > a span {
  display: none;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

</style>