<template>    
	<!-- Main Wrapper -->
	<div class="main-wrapper login-body">
		<div class="login-wrapper">
			<div class="container">
				<div class="loginbox">
					<div class="login-left">
						<img class="img-fluid" src="../../assets/img/TenAgents-logo.png" alt="Logo">
					</div>
					<div class="login-right">
						<div class="login-right-wrap">
							<h1>Login</h1>
							<p class="account-subtitle">Access to your dashboard</p>
							
							<!-- login Form -->
							<Form v-if="!otpForm" class="login" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
								<div class="form-group">
									<p class="settings-label">Phone Number <span class="star-red">*</span></p>
									<Field name="phone" type="text" v-model="phone" class="form-control" :class="{ 'is-invalid': errors.phone }" />
									<div class="invalid-feedback">{{errors.phone}}</div>
									<div class="emailshow text-danger" id="phone"></div>
								</div>
								<div class="form-group text-center">
									<button class="btn btn-primary btn-block" type="submit" value="Login">Generate OTP</button>
								</div>
							</Form>
							<!-- /login Form -->
							<!-- login Form -->
							<Form v-if="otpForm" class="login" @submit="onOtpFormSubmit" :validation-schema="otpSchema" v-slot="{ errors }">
								<div class="form-group">
									<p class="settings-label">Phone Number <span class="star-red">*</span></p>
									<input type="text" :value="phone" class="form-control" readonly />
								</div>
								<div class="form-group">
									<p class="settings-label">OTP <span class="star-red">*</span></p>
									<div class=" password-icon">
										<Field name="otp" type="password" v-model="otp" class="form-control pass-input" :class="{ 'is-invalid': errors.otp }" /><span class="fa fa-eye-slash toggle-password"></span>
										<div class="invalid-feedback">{{errors.otp}}</div>
										<div class="emailshow text-danger" id="otp"></div>
									</div>
								</div>
								<div class="form-group text-center">
									<button class="btn btn-primary btn-block" type="submit" value="Submit">Submit</button>
								</div>
							</Form>
							<!-- /login Form -->
							<div class="text-center forgotpass"><router-link to="/register">Register</router-link></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- /Main Wrapper -->		
</template>
<script>
	import { notify } from "@kyvg/vue3-notification"
	import {API} from '../../request'
	import { router } from '../../router';
	import { Form, Field } from 'vee-validate';
	import * as Yup from 'yup';
	export default {
	  	components: {
			Form,
			Field,
		},
		data: () => {
			return {
				otpForm: false,
				otp:'',
				phone: ''
			}
		},
		mounted() {
			if($('.toggle-password').length > 0) {
				$(document).on('click', '.toggle-password', function() {
					$(this).toggleClass("fa-eye fa-eye-slash");
					var input = $(".pass-input");
					if (input.attr("type") == "password") {
						input.attr("type", "text");
					} else {
						input.attr("type", "password");
					}
				});
			}
		},
		methods: {
			onSubmit() {
				document.getElementById("phone").innerHTML = ""
				let loginData = {
					phone : this.phone            
				}
				const $this = this
				API.post('studentPanel/doLogin', loginData)
				.then((result)=>{
					if(result.data.error === true) {
						notify({
							type: 'warn',
							title: result.data.message
						});
					} else {
						$this.otpForm = result.data.data.otp
						$this.phone = result.data.data.phone
						notify({
							type: 'success',
							title: result.data.message
						});
					}
				}).catch((error) => {
					console.warn(error);
				})
			},
			onOtpFormSubmit() {
				document.getElementById("otp").innerHTML = ""

				let loginOtpData = {
					phone : this.phone,
					otp : this.otp                
				}
				API.post('studentPanel/loginWithOtp', loginOtpData)
				.then((result)=>{
					if(result.data.error === true) {
						notify({
							type: 'warn',
							title: result.data.message
						});
					} else {
						notify({
							type: 'success',
							title: result.data.message
						});
						localStorage.setItem('token', result.data.token);
						localStorage.setItem('user', JSON.stringify(result.data.user));
						router.push('/profile/edit')
					}
				}).catch((error) => {
					console.warn(error);
				})
			}
		},	
		setup() {
		 	const schema = Yup.object().shape({
				phone: Yup.string()
					.required('Phone is required')
			});	
			const otpSchema = Yup.object().shape({
				otp: Yup.string()
					.required('Otp is required'),
			});			
			return {
				schema,
				otpSchema
			};	
		}	
	}
</script>